import React from 'react';

const TieMeKangarooDownSport = () => (
	<p>
		There&apos;s an old Australian stockman lying, dying. He gets himself up
		<br />
		onto one elbow and &apos;e turns to his mates, who are all gathered around
		and &apos;e says:
		<br />
		<br />
		<br />
		Watch me wallabies feed, mate
		<br />
		Watch me wallabies feed,
		<br />
		They&apos;re a dangerous breed, mate
		<br />
		So watch me wallabies feed
		<br />
		Altogether now!
		<br />
		<br />
		CHORUS:
		<br />
		Tie me kangaroo down, sport
		<br />
		Tie me kangaroo down
		<br />
		Tie me kangaroo down, sport
		<br />
		Tie me kangaroo down
		<br />
		<br />
		Keep me cockatoo cool, Curl,
		<br />
		Keep me cockatoo cool
		<br />
		Ah, don&apos;t go acting the fool, Curl
		<br />
		Just keep me cockatoo cool
		<br />
		Altogether now!
		<br />
		<br />
		(CHORUS)
		<br />
		<br />
		&apos;n&apos; take me koala back, Jack
		<br />
		Take me koala back
		<br />
		He lives somewhere out on the track, Mac
		<br />
		So take me koala back
		<br />
		Altogether now!
		<br />
		<br />
		(CHORUS)
		<br />
		<br />
		Let me abos go loose, Lew
		<br />
		Let me abos go loose
		<br />
		They&apos;re of no further use, Lew
		<br />
		So let me abos go loose
		<br />
		Altogether now!
		<br />
		<br />
		(CHORUS)
		<br />
		<br />
		And mind me platypus duck, Bill
		<br />
		Mind me platypus duck
		<br />
		Ah, don&apos;t let &apos;im go running amok, Bill
		<br />
		Just mind me platypus duck
		<br />
		Altogether now!
		<br />
		<br />
		(CHORUS)
		<br />
		<br />
		Play your didgeridoo, Blue
		<br />
		Play your didgeridoo
		<br />
		Ah, like, keep playin&apos; &apos;til I shoot thru, Blue
		<br />
		Play your didgeridoo
		<br />
		Altogether now!
		<br />
		<br />
		(CHORUS)
		<br />
		<br />
		Tan me hide when I&apos;m dead, Fred
		<br />
		Tan me hide when I&apos;m dead
		<br />
		So we tanned his hide when he died, Clyde
		<br />
		And that&apos;s it hangin&apos; on the shed!!
		<br />
		Altogether now!
		<br />
		<br />
		(CHORUS)
	</p>
);

export default TieMeKangarooDownSport;
