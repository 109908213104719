import React from 'react';

const MichaelRowTheBoatAshore = () => (
	<p>
		Michael, row the boat ashore, hallelujah
		<br />
		Michael, row the boat ashore, hallelujah
		<br />
		Sister, help to trim the sail, hallelujah
		<br />
		Sister, help to trim the sail, hallelujah
		<br />
		<br />
		The river is deep and the river is wide, hallelujah
		<br />
		Green pastures on the other side, hallelujah
		<br />
		<br />
		Michael, row the boat ashore, hallelujah
		<br />
		Michael, row the boat ashore, hallelujah
		<br />
		Sister, help to trim the sail, hallelujah
		<br />
		Sister, help to trim the sail, hallelujah
		<br />
		<br />
		Jordan&apos;s river is chilly and cold, hallelujah
		<br />
		Chills the body but not the soul, hallelujah
		<br />
		<br />
		Michael, row the boat ashore, hallelujah
		<br />
		Michael, row the boat ashore, hallelujah
		<br />
		Sister, help to trim the sail, hallelujah
		<br />
		Sister, help to trim the sail, hallelujah
		<br />
		<br />
		The river is deep and the river is wide, hallelujah
		<br />
		Milk and honey on the other side, hallelujah
		<br />
		<br />
		Michael, row the boat ashore, hallelujah
		<br />
		Michael, row the boat ashore, hallelujah
		<br />
		Sister, help to trim the sail, hallelujah
		<br />
		Sister, help to trim the sail, hallelujah
	</p>
);

export default MichaelRowTheBoatAshore;
