import React from 'react';

const OneTinSoldier = () => (
	<p>
		Listen children to a story
		<br />
		That was written long ago
		<br />
		About a kingdom, on a mountain
		<br />
		And the valley folk below
		<br />
		On the mountain was a treasure
		<br />
		Burred deep beneath a stone
		<br />
		And the valley people swore
		<br />
		Theyd have it for their very own
		<br />
		<br />
		Go ahead and hate your neighbor
		<br />
		Go ahead and cheat a friend
		<br />
		Do it in the name of Heaven
		<br />
		You can justify it in the end
		<br />
		There wont be any trumpets blowing
		<br />
		Come the judgement day
		<br />
		On the bloody morning after...
		<br />
		One tin soldier rides away
		<br />
		<br />
		So the people of the valley
		<br />
		Sent a message up the hill
		<br />
		Asking for the buried treasure
		<br />
		Tons of gold for which they&apos;d kill
		<br />
		Came an answer from the kingdom
		<br />
		With our brothers we will share
		<br />
		All the secrets of our mountain
		<br />
		All the riches buried there
		<br />
		<br />
		Now the valley cried in anger
		<br />
		Mount your horses
		<br />
		Draw your sword
		<br />
		And they killed the mountain people
		<br />
		Sure they&apos;d won their just reward
		<br />
		Now they stood beside the treasure
		<br />
		On the mountain dark and red
		<br />
		Turned the stone and looked beneath it
		<br />
		Peace on Earth
		<br />
		Was all it said
		<br />
		<br />
		Go ahead and hate your neighbor
		<br />
		Go ahead and cheat a friend
		<br />
		Do it in the name of Heaven
		<br />
		You can justify it in the end
		<br />
		There wont be any trumpets blowing
		<br />
		Come the judgement day
		<br />
		On the bloody morning after...
		<br />
		One tin soldier rides away
		<br />
		<br />
		Go ahead and hate your neighbor
		<br />
		Go ahead and cheat a friend
		<br />
		Do it in the name of Heaven
		<br />
		You can justify it in the end
		<br />
		There wont be any trumpets blowing
		<br />
		Come the judgement day
		<br />
		On the bloody morning after...
		<br />
		One tin soldier rides away
	</p>
);

export default OneTinSoldier;
