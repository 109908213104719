import React from 'react';

const MichaelFinnegan = () => (
	<p>
		There was an old man named Michael Finnegan
		<br />
		He had whiskers on his chinnegan
		<br />
		They fell out and then grew in again
		<br />
		Poor old Michael Finnegan
		<br />
		Begin again.
		<br />
		<br />
		There was an old man named Michael Finnegan
		<br />
		He went fishing with a pinnegan
		<br />
		Caught a fish and dropped it in again
		<br />
		Poor old Michael Finnegan
		<br />
		Begin again.
		<br />
		<br />
		There was an old man named Michael Finnegan
		<br />
		He grew fat and then grew thin again
		<br />
		Then he died and had to begin again
		<br />
		Poor old Michael Finnegan
		<br />
		Begin again.
	</p>
);

export default MichaelFinnegan;
