import React from 'react';

const WhatTheWorldNeedsNowIsLove = () => (
	<p>
		What the world needs now is love, sweet love
		<br />
		It&apos;s the only thing that there&apos;s just too little of
		<br />
		What the world needs now is love, sweet love
		<br />
		No not just for some but for everyone
		<br />
		<br />
		Lord we don&apos;t need another mountain
		<br />
		There are mountains and hillsides
		<br />
		Enough to climb
		<br />
		There are oceans and rivers
		<br />
		Enough to cross. enough to last
		<br />
		&apos;Till the end of time
		<br />
		<br />
		What the world needs now is love, sweet love
		<br />
		It&apos;s the only thing that there&apos;s just too little of
		<br />
		What the world needs now is love, sweet love
		<br />
		No not just for some but for everyone
		<br />
		<br />
		Lord we don&apos;t need another meadow
		<br />
		There are corn fields and wheat fields
		<br />
		Enough to grow
		<br />
		There are sun beams and moon beams
		<br />
		Enough to shine
		<br />
		Oh listen Lord if you want to know
		<br />
		<br />
		What the world needs now is love, sweet love
		<br />
		It&apos;s the only thing that there&apos;s just too little of
		<br />
		What the world needs now is love, sweet love
		<br />
		No not just for some but for everyone
		<br />
		<br />
		What the world needs now is love, sweet love
		<br />
		What the world needs now is love, sweet love
		<br />
		What the world needs now is love, sweet love
		<br />
		What the world needs now...
	</p>
);

export default WhatTheWorldNeedsNowIsLove;
