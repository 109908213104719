import React from 'react';

const GodBlessTheUSA = () => (
	<p>
		If tomorrow all the things were gone I&apos;d worked for all my life
		<br />
		And I had to start again with just my children and my wife
		<br />
		I&apos;d thank my lucky stars to be livin&apos; here today
		<br />
		&apos;Cause the flag still stands for freedom and they can&apos;t take that
		away
		<br />
		<br />
		And I&apos;m proud to be an American where at least I know I&apos;m free
		<br />
		And I won&apos;t forget the men who died, who gave that right to me
		<br />
		And I&apos;d gladly stand up next to you and defend her still today
		<br />
		&apos;Cause there ain&apos;t no doubt I love this land
		<br />
		God bless the USA
		<br />
		<br />
		From the lakes of Minnesota, to the hills of Tennessee
		<br />
		Across the plains of Texas, from sea to shining sea
		<br />
		From Detroit down to Houston and New York to LA
		<br />
		Well, there&apos;s pride in every American heart
		<br />
		And it&apos;s time we stand and say that
		<br />
		<br />
		<i>[2x]</i>
		<br />
		I&apos;m proud to be an American where at least I know I&apos;m free
		<br />
		And I won&apos;t forget the men who died, who gave that right to me
		<br />
		And I&apos;d gladly stand up next to you and defend her still today
		<br />
		&apos;Cause there ain&apos;t no doubt I love this land
		<br />
		God bless the USA
	</p>
);

export default GodBlessTheUSA;
