import React from 'react';

const BabyBaluga = () => (
	<p>
		Baby beluga in the deep blue sea
		<br />
		Swim so wild and you swim so free
		<br />
		Heaven above and the sea below
		<br />
		And a little white whale on the go
		<br />
		<br />
		Baby beluga, baby beluga
		<br />
		Is the water warm
		<br />
		Is your mama home with you, so happy
		<br />
		<br />
		Way down yonder where the dolphins play
		<br />
		Where you dive and splash all day
		<br />
		Waves roll in and the waves roll out
		<br />
		See the water squirtin&apos; out of your spout
		<br />
		<br />
		Baby beluga, oh, baby beluga
		<br />
		Sing your little song
		<br />
		Sing for all your friends, we like to hear you
		<br />
		<br />
		When it&apos;s dark, you&apos;re home and fed
		<br />
		Curl up, snug in your water bed
		<br />
		Moon is shining and the stars are out
		<br />
		Good night, little whale, good night
		<br />
		<br />
		Baby beluga, oh, baby beluga
		<br />
		With tomorrow&apos;s sun, another day&apos;s begun
		<br />
		You&apos;ll soon be wakin&apos;
		<br />
		<br />
		Baby beluga in the deep blue sea
		<br />
		Swim so wild and you swim so free
		<br />
		Heaven above and the sea below
		<br />
		And a little white whale on the go
		<br />
		You&apos;re just a little white whale on the go
		<br />
	</p>
);

export default BabyBaluga;
