import React from 'react';

const YouAreMySunshine = () => (
	<p>
		The other night, dear, as I lay sleeping
		<br />
		I dreamed I held you in my arms
		<br />
		When I awoke, dear, I was mistaken
		<br />
		And I hung my head and cried
		<br />
		<br />
		You are my sunshine, my only sunshine
		<br />
		You make me happy when skies are grey
		<br />
		You&apos;ll never know, dear, how much I love you
		<br />
		Please don&apos;t take my sunshine away
		<br />
		<br />
		I&apos;ll always love you and make you happy
		<br />
		If you will only say the same
		<br />
		But if you leave me to love another
		<br />
		You&apos;ll regret it all some day
		<br />
		<br />
		You told me once, dear, you really loved me
		<br />
		And no one else could come between
		<br />
		But now you&apos;ve left me and love another
		<br />
		You have shattered all my dreams
	</p>
);

export default YouAreMySunshine;
