import React from 'react';

const TheSoundsOfSilence = () => (
	<p>
		Hello darkness, my old friend
		<br />
		I&apos;ve come to talk with you again
		<br />
		Because a vision softly creeping
		<br />
		Left its seeds while I was sleeping
		<br />
		And the vision that was planted in my brain
		<br />
		Still remains
		<br />
		Within the sound of silence
		<br />
		<br />
		In restless dreams I walked alone
		<br />
		Narrow streets of cobblestone
		<br />
		‘Neath the halo of a streetlamp
		<br />
		I turned my collar to the cold and damp
		<br />
		When my eyes were stabbed by the flash of a neon light
		<br />
		That split the night
		<br />
		And touched the sound of silence
		<br />
		<br />
		And in the naked light I saw
		<br />
		Ten thousand people, maybe more
		<br />
		People talking without speaking
		<br />
		People hearing without listening
		<br />
		People writing songs that voices never share
		<br />
		No one dare
		<br />
		Disturb the sound of silence
		<br />
		<br />
		“Fools” said I, “You do not know
		<br />
		Silence like a cancer grows
		<br />
		Hear my words that I might teach you
		<br />
		Take my arms that I might reach you”
		<br />
		But my words like silent raindrops fell
		<br />
		And echoed in the wells of silence
		<br />
		<br />
		And the people bowed and prayed
		<br />
		To the neon god they made
		<br />
		And the sign flashed out its warning
		<br />
		In the words that it was forming
		<br />
		And the sign said “The words of the prophets
		<br />
		Are written on the subway walls
		<br />
		And tenement halls
		<br />
		And whispered in the sounds of silence”
	</p>
);

export default TheSoundsOfSilence;
