import React from 'react';

const SvgBackArrow = ({ style }: SvgBackArrowTypes) => (
	<svg style={style} viewBox="0 0 512 512">
		<path d="M327.3,98.9l-2.1,1.8l-156.5,136c-5.3,4.6-8.6,11.5-8.6,19.2c0,7.7,3.4,14.6,8.6,19.2L324.9,411l2.6,2.3  c2.5,1.7,5.5,2.7,8.7,2.7c8.7,0,15.8-7.4,15.8-16.6h0V112.6h0c0-9.2-7.1-16.6-15.8-16.6C332.9,96,329.8,97.1,327.3,98.9z" />
	</svg>
);

type SvgBackArrowTypes = {
	style?: React.CSSProperties;
};

export default SvgBackArrow;
