import React from 'react';

const TheCatCameBack = () => (
	<p>
		Old Mr. Johnson had troubles of his own
		<br />
		He had a yellow cat that wouldn&apos;t leave his home
		<br />
		He tried and he tried to give the cat away
		<br />
		He gave it to a man going far far away
		<br />
		<br />
		But the cat came back, the very next day
		<br />
		The cat came back
		<br />
		(they thought he was a goner)
		<br />
		but the cat came back, he just couldn&apos;t stay
		<br />
		Away away away
		<br />
		MEOW!
		<br />
		<br />
		So he gave it to a woman going
		<br />
		Up in a balloon
		<br />
		He told her for to take it to the
		<br />
		Man up in the moon
		<br />
		The balloon came down about
		<br />
		90 miles away
		<br />
		and where the woman is now,
		<br />
		well I cannot say
		<br />
		<br />
		but the cat came back…
		<br />
		<br />
		so he gave it to a man going way out West
		<br />
		he told him for to take it to the one he loved the best
		<br />
		first the train hit the track,
		<br />
		then it jumped the rail
		<br />
		and nobody was left to tell the awful tale
		<br />
		<br />
		But the cat came back…
	</p>
);

export default TheCatCameBack;
