import React from 'react';

const GoodAsYou = () => (
	<p>
		See the way you&apos;re takin&apos; care of your mama
		<br />
		The way you&apos;re takin&apos; care of me
		<br />
		The way you light up any room, girl
		<br />
		You&apos;re what this world should be
		<br />
		<br />
		Tomorrow, tonight, the rest of my life
		<br />
		I wanna be the man you want me to be
		<br />
		So startin&apos; right now, girl, tell me everything you need
		<br />
		<br />
		I just wanna wake up everyday here in this bed
		<br />
		Never leave &apos;I love you&apos; left unsaid
		<br />
		It might take a hundred lifetimes to do
		<br />
		But baby, I just wanna be good as you
		<br />
		<br />
		Girl, you&apos;re more than just the surface
		<br />
		Yeah, you got that heart of gold (that heart of gold)
		<br />
		So when it comes to lovin&apos; you, baby
		<br />
		No, it will never get old
		<br />
		<br />
		Tomorrow, tonight, the rest of my life
		<br />
		I wanna be the man you want me to be
		<br />
		So startin&apos; right now, girl, tell me everything you need
		<br />
		<br />
		I just wanna wake up everyday here in this bed
		<br />
		Never leave &apos;I love you&apos; left unsaid
		<br />
		It might take a hundred lifetimes to do (lifetimes to do)
		<br />
		But baby, I just wanna be good as you
		<br />
		Good as you, baby
		<br />
		<br />
		I just wanna wake up everyday here in this bed
		<br />
		Never leave I love you left unsaid
		<br />
		It might take a hundred lifetimes to do
		<br />
		But baby, I just wanna be good as you
		<br />
		I just wanna wake up everyday here in this bed (here with you, baby)
		<br />
		Never leave I love you left unsaid (never leave it unsaid)
		<br />
		It might take a hundred lifetimes to do (a hundred lifetimes now)
		<br />
		But baby, I just wanna be good as you
		<br />
		Baby, I just wanna be good as you
	</p>
);

export default GoodAsYou;
