import React from 'react';

const WalkingOnSunshine = () => (
	<p>
		Oh!
		<br />
		<br />
		Ohhhh, yeeeh
		<br />
		<br />
		I used to think maybe you loved me now baby I&apos;m sure
		<br />
		And I just can&apos;t wait till the day when you knock on my door
		<br />
		Now everytime I go for the mailbox, gotta hold myself down
		<br />
		&apos;Cause I just can&apos;t wait till you write me you&apos;re coming
		around
		<br />
		<br />
		I&apos;m walking on sunshine, wooah
		<br />
		I&apos;m walking on sunshine, woooah
		<br />
		I&apos;m walking on sunshine, woooah
		<br />
		And don&apos;t it feel good!
		<br />
		<br />
		Hey, alright now
		<br />
		And dont it feel good!
		<br />
		Hey yeah
		<br />
		<br />
		I used to think maybe you loved me, now I know that it&apos;s true
		<br />
		And I don&apos;t want to spend my whole life, just waiting for you
		<br />
		<br />
		Now I don&apos;t want u back for the weekend
		<br />
		Not back for a day, no no no
		<br />
		I said baby I just want you back and I want you to stay
		<br />
		<br />
		Woah yeah!
		<br />
		I&apos;m walking on sunshine, wooah
		<br />
		I&apos;m walking on sunshine, woooah
		<br />
		I&apos;m walking on sunshine, woooah
		<br />
		And don&apos;t it feel good!!
		<br />
		<br />
		Hey, alright now
		<br />
		And don&apos;t it feel good!!
		<br />
		Yeah, oh yeah
		<br />
		And don&apos;t it feel good!!
		<br />
		<br />
		Walking on sunshine
		<br />
		Walking on sunshine
		<br />
		<br />
		I feel alive, I feel the love, I feel the love that&apos;s really real
		<br />
		I feel alive, I feel the love, I feel the love that&apos;s really real
		<br />
		<br />
		I&apos;m on sunshine baby oh, oh yeah
		<br />
		I&apos;m on sunshine baby oh
		<br />
		<br />
		I&apos;m walking on sunshine, wooah
		<br />
		I&apos;m walking on sunshine, wooah
		<br />
		I&apos;m walking on sunshine, woooah
		<br />
		And don&apos;t it feel good
		<br />
		<br />
		Hey, alright now
		<br />
		And don&apos;t it feel good
		<br />
		<br />
		I&apos;ll say it, say it, say it again now
		<br />
		And don&apos;t it feel good!!
		<br />
		<br />
		Ohhhh, yeahhhh
		<br />
		And don&apos;t it feel good
		<br />
		Now don&apos;t it, don&apos;t it, don&apos;t it, don&apos;t it, don&apos;t
		it, don&apos;t it feel good
		<br />
		<br />
		I&apos;ll say it, say it, say it again now
		<br />
		And don&apos;t feel good
		<br />
		Now don&apos;t it, don&apos;t it, don&apos;t it, don&apos;t it, don&apos;t
		it, don&apos;t it feel good
		<br />
		<br />
		Tell me, tell me, tell me again now
		<br />
		And don&apos;t it feel good
		<br />
		Ohhhh, yeahhhh
		<br />
		And don&apos;t it feel good
		<br />
		Oh don&apos;t it feel good, don&apos;t it feel good
		<br />
		Now don&apos;t it feel good?
		<br />
		Oh yeah, oh yeah, oh yeah (Don&apos;t it feel good)
	</p>
);

export default WalkingOnSunshine;
