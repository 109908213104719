import React from 'react';

const SvgEmojiDizzy = ({ style }: SvgEmojiDizzyTypes) => (
	<svg style={style} viewBox="0 0 512 512">
		<path d="M256 272c-35.36 0-64.06 28.75-64.06 64S220.6 400 256 400s64.06-28.75 64.06-64S291.4 272 256 272zM382.2 145.9c-7.75-7.75-20.46-7.75-28.34 0l-17.8 17.88l-17.8-17.88c-7.871-7.75-20.59-7.75-28.34 0c-7.75 7.875-7.75 20.5 0 28.38l17.92 17.88L289.9 210c-7.75 7.75-7.75 20.5 0 28.25c7.75 7.875 20.46 7.875 28.34 0l17.8-17.75l17.8 17.75c7.75 7.875 20.46 7.875 28.21 0c7.871-7.75 7.871-20.5 0-28.25L364.3 192l17.92-17.88C389.9 166.3 389.9 153.8 382.2 145.9zM222.2 238.1c7.75-7.875 7.75-20.5 0-28.38L204.3 192l17.92-17.88c7.75-7.875 7.75-20.5 0-28.38c-7.871-7.75-20.46-7.75-28.34 0l-17.92 18L158.2 145.9c-7.75-7.75-20.46-7.75-28.21 0c-7.871 7.875-7.871 20.5 0 28.38l17.8 17.88L129.9 210c-7.871 7.75-7.871 20.5 0 28.25c7.75 7.875 20.46 7.875 28.21 0L175.1 220.5l17.8 17.75C201.8 246 214.3 246 222.2 238.1zM256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 464c-114.7 0-208-93.31-208-208S141.3 48 256 48s208 93.31 208 208S370.7 464 256 464z" />
	</svg>
);

type SvgEmojiDizzyTypes = {
	style?: React.CSSProperties;
};

export default SvgEmojiDizzy;
