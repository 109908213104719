import React from 'react';

const CountryRoads = () => (
	<p>
		Almost heaven, West Virginia, Blue Ridge Mountains, Shenandoah River.
		<br />
		Life is old there, older than the trees, younger than the mountains, blowing
		like a breeze.
		<br />
		<br />
		Country roads, take me home to the place I belong.
		<br />
		West Virginia, mountain mamma, take me home, country roads.
		<br />
		<br />
		All my memories gather round her, miner&apos;s lady, stranger to blue water.
		<br />
		Dark and dusty, painted on the sky, misty taste of moonshine, teardrop in my
		eye.
		<br />
		<br />
		Country roads, take me home to the place I belong.
		<br />
		West Virginia, mountain mamma, take me home, country roads.
		<br />
		<br />
		I hear her voice in the morning hour, she calls me, the radio reminds me of
		my home far away.
		<br />
		And driving down the road I get a feeling that I should have been home
		yesterday, yesterday.
		<br />
		<br />
		Country roads, take me home to the place I belong.
		<br />
		West Virginia, mountain mamma, take me home, country roads.
		<br />
		<br />
		Country roads, take me home to the place I belong.
		<br />
		West Virginia, mountain mamma, take me home, country roads
		<br />
		<br />
		Take me home now, country roads,
		<br />
		Take me home now, country roads.
	</p>
);

export default CountryRoads;
