import React from 'react';

const TurnTurnTurn = () => (
	<p>
		To everything - turn, turn, turn
		<br />
		There is a season - turn, turn, turn
		<br />
		And a time to every purpose under heaven
		<br />
		<br />
		A time to be born, a time to die
		<br />
		A time to plant, a time to reap
		<br />
		A time to kill, a time to heal
		<br />
		A time to laugh, a time to weep
		<br />
		<br />
		To everything - turn, turn, turn
		<br />
		There is a season - turn, turn, turn
		<br />
		And a time to every purpose under heaven
		<br />
		<br />
		A time to build up, a time to break down
		<br />
		A time to dance, a time to mourn
		<br />
		A time to cast away stones
		<br />
		A time to gather stones together
		<br />
		<br />
		To everything - turn, turn, turn
		<br />
		There is a season - turn, turn, turn
		<br />
		And a time to every purpose under heaven
		<br />
		<br />
		A time of love, a time of hate
		<br />
		A time of war, a time of peace
		<br />
		A time you may embrace
		<br />
		A time to refrain from embracing
		<br />
		<br />
		To everything - turn, turn, turn
		<br />
		There is a season - turn, turn, turn
		<br />
		And a time to every purpose under heaven
		<br />
		<br />
		A time to gain, a time to lose
		<br />
		A time to rend, a time to sew
		<br />
		A time for love, a time for hate
		<br />A time for peace, I swear it&apos;s not too late!
	</p>
);

export default TurnTurnTurn;
