import React from 'react';

const LeavingOnAJetPlane = () => (
	<p>
		All my bags are packed, I&apos;m ready to go, I&apos;m standing here outside
		your door,
		<br />
		I hate to wake you up to say good-bye.
		<br />
		But the dawn is breaking, it&apos;s early morn, the taxi&apos;s waiting
		He&apos;s blowing his horn.
		<br />
		Already I&apos;m so lonesome I could die.
		<br />
		So kiss me and smile for me, tell me that you&apos;ll wait for me, hold me
		like you&apos;ll never let me go.
		<br />
		&apos;Cause I&apos;m leaving on a jet plane, don&apos;t know when I&apos;ll
		be back again. Oh, babe, I hate to go.
		<br />
		<br />
		There&apos;s so many times I&apos;ve let you down, so many times I&apos;ve
		played around,
		<br />
		I tell you now they don&apos;t mean a thing.
		<br />
		Every place I go I&apos;ll think of you, every song I sing I&apos;ll sing
		for you,
		<br />
		when I come back, I&apos;ll bring your wedding ring.
		<br />
		So kiss me and smile for me, tell me that you&apos;ll wait for me, hold me
		like you&apos;ll never let me go.
		<br />
		&apos;Cause I&apos;m leaving on a jet plane, don&apos;t know when I&apos;ll
		be back again. Oh, babe, I hate to go.
		<br />
		<br />
		Now the time has come to leave you, one more time let me kiss you,
		<br />
		then close your eyes, I&apos;ll be on my way.
		<br />
		Dream about the days to come when I won&apos;t have to leave alone,
		<br />
		about the times I won&apos;t have to say:
		<br />
		kiss me and smile for me, tell me that you&apos;ll wait for me, hold me like
		you&apos;ll never let me go.
		<br />
		&apos;Cause I&apos;m leaving on a jet plane, don&apos;t know when I&apos;ll
		be back again. Oh, babe, I hate to go.
		<br />
		I&apos;m leaving on a jet plane, don&apos;t know when I&apos;ll be back
		again. Oh, babe, I hate to go.
	</p>
);

export default LeavingOnAJetPlane;
