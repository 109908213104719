import React from 'react';

const ThisLandIsYourLand = () => (
	<p>
		This land is your land, this land is my land,
		<br />
		From California, to the New York Island,
		<br />
		From the Redwood Forest, to the Gulf Stream Waters,
		<br />
		This land was made for you and me.
		<br />
		<br />
		I roamed and rambled, and I followed my footsteps
		<br />
		To the sparkling sands of her diamond deserts
		<br />
		And all around me a voice was singing
		<br />
		This land was made for you and me!
		<br />
		<br />
		This land is your land, this land is my land,
		<br />
		From California, to the New York Island,
		<br />
		From the Redwood Forest, to the Gulf Stream Waters,
		<br />
		This land was made for you and me.
		<br />
		<br />
		As I went walking that ribbon of highway
		<br />
		I saw above me that endless skyway
		<br />
		I saw below me those golden valleys
		<br />
		This land was made for you and me!
		<br />
		<br />
		This land is your land, this land is my land,
		<br />
		From California, to the New York Island,
		<br />
		From the Redwood Forest, to the Gulf Stream Waters,
		<br />
		This land was made for you and me.
		<br />
		<br />
		As the sun was shining, and I was strolling,
		<br />
		And the wheat fields waving, and the dust clouds rolling,
		<br />
		As the fog was lifting, a voice was saying
		<br />
		&quot;This land was made for you and me!&quot;
		<br />
		<br />
		This land is your land, this land is my land,
		<br />
		From California, to the New York Island,
		<br />
		From the Redwood Forest, to the Gulf Stream Waters,
		<br />
		This land was made for you and me.
	</p>
);

export default ThisLandIsYourLand;
