import React from 'react';

const YellowSubmarine = () => (
	<p>
		In the town where I was born
		<br />
		Lived a man who sailed to sea
		<br />
		And he told us of his life
		<br />
		In the land of submarines
		<br />
		<br />
		So we sailed up to the sun
		<br />
		Till we found the sea of green
		<br />
		And we lived beneath the waves
		<br />
		In our yellow submarine
		<br />
		<br />
		We all live in a yellow submarine
		<br />
		Yellow submarine, yellow submarine
		<br />
		We all live in a yellow submarine
		<br />
		Yellow submarine, yellow submarine
		<br />
		<br />
		And our friends are all on board
		<br />
		Many more of them live next door
		<br />
		And the band begins to play
		<br />
		<br />
		We all live in a yellow submarine
		<br />
		Yellow submarine, yellow submarine
		<br />
		We all live in a yellow submarine
		<br />
		Yellow submarine, yellow submarine
		<br />
		<br />
		As we live a life of ease
		<br />
		Everyone of us has all we need
		<br />
		Sky of blue and sea of green
		<br />
		In our yellow submarine
		<br />
		<br />
		We all live in a yellow submarine
		<br />
		Yellow submarine, yellow submarine
		<br />
		We all live in a yellow submarine
		<br />
		Yellow submarine, yellow submarine
		<br />
		We all live in a yellow submarine
		<br />
		Yellow submarine, yellow submarine
		<br />
		We all live in a yellow submarine
		<br />
		Yellow submarine, yellow submarine
	</p>
);

export default YellowSubmarine;
