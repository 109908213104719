import React from 'react';

const HesGotTheWholeWorldInHisHands = () => (
	<p>
		He&apos;s got the whole world in his hands
		<br />
		He&apos;s got the whole wide world in his hands
		<br />
		He&apos;s got the whole wide world in his hands
		<br />
		He&apos;s got the whole world in his hands
		<br />
		<br />
		He&apos;s got the little bitty baby in his hands
		<br />
		He&apos;s got the little bitty baby in his hands
		<br />
		He&apos;s got the little bitty baby in his hands
		<br />
		He&apos;s got the whole world in his hands
		<br />
		He&apos;s got the whole world in his hands...
		<br />
		<br />
		He&apos;s got you and me brother in his hands
		<br />
		He&apos;s got you and me sister in his hands
		<br />
		He&apos;s got you and me brother in his hands
		<br />
		He&apos;s got the whole world in his hands
		<br />
		He&apos;s got the whole world in his hands...
		<br />
		<br />
		He&apos;s got everybody here in his hands
		<br />
		He&apos;s got everybody here in his hands
		<br />
		He&apos;s got everybody here in his hands
		<br />
		He&apos;s got the whole world in his hands
		<br />
		He&apos;s got the whole world in his hands...
		<br />
		He&apos;s got you and me brother...
		<br />
		He&apos;s got the whole world in his hands...
	</p>
);

export default HesGotTheWholeWorldInHisHands;
