import React from 'react';

const WhereHaveAllTheFlowersGone = () => (
	<p>
		Where have all the flowers gone, long time passing?
		<br />
		Where have all the flowers gone, long time ago?
		<br />
		Where have all the flowers gone?
		<br />
		Young girls have picked them everyone.
		<br />
		Oh, when will they ever learn?
		<br />
		Oh, when will they ever learn?
		<br />
		<br />
		Where have all the young girls gone, long time passing?
		<br />
		Where have all the young girls gone, long time ago?
		<br />
		Where have all the young girls gone?
		<br />
		Gone for husbands everyone.
		<br />
		Oh, when will they ever learn?
		<br />
		Oh, when will they ever learn?
		<br />
		<br />
		Where have all the husbands gone, long time passing?
		<br />
		Where have all the husbands gone, long time ago?
		<br />
		Where have all the husbands gone?
		<br />
		Gone for soldiers everyone
		<br />
		Oh, when will they ever learn?
		<br />
		Oh, when will they ever learn?
		<br />
		<br />
		Where have all the soldiers gone, long time passing?
		<br />
		Where have all the soldiers gone, long time ago?
		<br />
		Where have all the soldiers gone?
		<br />
		Gone to graveyards, everyone.
		<br />
		Oh, when will they ever learn?
		<br />
		Oh, when will they ever learn?
		<br />
		<br />
		Where have all the graveyards gone, long time passing?
		<br />
		Where have all the graveyards gone, long time ago?
		<br />
		Where have all the graveyards gone?
		<br />
		Gone to flowers, everyone.
		<br />
		Oh, when will they ever learn?
		<br />
		Oh, when will they ever learn?
		<br />
		<br />
		Where have all the flowers gone, long time passing?
		<br />
		Where have all the flowers gone, long time ago?
		<br />
		Where have all the flowers gone?
		<br />
		Young girls have picked them everyone.
		<br />
		Oh, when will they ever learn?
		<br />
		Oh, when will they ever learn?
	</p>
);

export default WhereHaveAllTheFlowersGone;
