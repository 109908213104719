import React from 'react';

const ItsYourLove = () => (
	<p>
		Yeah, yeah, yeah, mmhmm
		<br />
		<br />
		Dancing in the dark
		<br />
		Middle of the night
		<br />
		Taking your heart
		<br />
		And holding it tight
		<br />
		Emotional touch
		<br />
		Touching my skin
		<br />
		And askin&apos; you to do
		<br />
		What you&apos;ve been doing
		<br />
		All over again
		<br />
		<br />
		Oh, it&apos;s a beautiful thing
		<br />
		Don&apos;t think I can keep it all in
		<br />
		I just gotta let you know
		<br />
		What it is that won&apos;t let me go
		<br />
		<br />
		It&apos;s your love
		<br />
		It just does something to me
		<br />
		It sends a shock right through me
		<br />
		I can&apos;t get enough
		<br />
		And if you wonder
		<br />
		About the spell I&apos;m under
		<br />
		Oh it&apos;s your love
		<br />
		<br />
		Better than I was
		<br />
		More than I am
		<br />
		And all of this happened
		<br />
		By taking your hand
		<br />
		And who I am now
		<br />
		Is who I wanted to be
		<br />
		And now that we&apos;re together
		<br />
		I&apos;m stronger than ever, I&apos;m happy and free
		<br />
		<br />
		Oh, it&apos;s a beautiful thing
		<br />
		Don&apos;t think I can keep it all in (oh oh)
		<br />
		And if you ask me why I&apos;ve changed
		<br />
		All I gotta do is say your sweet name
		<br />
		<br />
		It&apos;s your love
		<br />
		It just does something to me
		<br />
		It sends a shock right through me
		<br />
		I can&apos;t get enough
		<br />
		And if you wonder
		<br />
		About the spell I&apos;m under
		<br />
		Oh it&apos;s your love
		<br />
		(Woah oh baby)
		<br />
		(Oh, oh, oh)
		<br />
		<br />
		Oh, it&apos;s a beautiful thing
		<br />
		Don&apos;t think I can keep it all in
		<br />
		I just gotta let you know
		<br />
		What it is that won&apos;t let me go
		<br />
		<br />
		It&apos;s your love
		<br />
		It just does something to me
		<br />
		It sends a shock right through me
		<br />
		I can&apos;t get enough
		<br />
		(Oh) And if you wonder
		<br />
		About the spell I&apos;m under
		<br />
		Oh it&apos;s your love
		<br />
		It&apos;s your love
		<br />
		It&apos;s your love
	</p>
);

export default ItsYourLove;
